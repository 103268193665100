<template>
  <v-container class="mx-auto" style="max-width: 1500px">
    <v-card class="border-radius-xl card-shadow pt-4 pb-10 mb-5 px-4">
      <div class="py-4 mt-2">
        <v-row no-gutters>
          <v-col cols="9">
            <h2 class="text-typo">Багшийн үнэлгээ2 баталгаажуулалт</h2>
            <p class="text-body">
              Бүртгэлтэй багшийн тоо
              <span class="blue--text" v-if="teachers">{{
                teachers.length
              }}</span>
            </p>
          </v-col>
          <v-col cols="3">
            <v-select
              style="background-color: #f2e2fa"
              :items="[
                {
                  yearId: 1,
                  name: preText + ' xичээлийн жил, 1-р xагас жил',
                },
                {
                  yearId: 2,
                  name: preText + ' xичээлийн жил, 2-р xагас жил',
                },
              ]"
              v-model="selectedHalfYear"
              label="Улирал"
              return-object
              item-text="name"
              item-value="yearId"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :headers="headerNames"
        :items="teachers"
        hide-details
        class="teacherMonitoringTable"
        hide-default-footer
        :items-per-page="-1"
        v-if="teachers"
      >
        <template slot="item" slot-scope="props">
          <tr style="cursor: pointer">
            <td @click="print(props.item)">{{ props.item.index }}</td>
            <td style="width: 16%" class="px-0 blue--text pl-2">
              {{ props.item["DISPLAY_NAME"] }},
            </td>
            <td>
              {{
                (props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ] &&
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ].electiveLessonNumber
                  ? props.item[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        userData.school.currentYear
                    ].electiveLessonNumber
                  : 0) +
                (props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ] &&
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ].mandatoryLessonNumber
                  ? props.item[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        userData.school.currentYear
                    ].mandatoryLessonNumber
                  : 0)
              }}
            </td>
            <td style="color: green">
              {{
                _getConfirmationProcent(props.item) >= 100 ||
                _getConfirmationProcent(props.item) == 0
                  ? _getConfirmationProcent(props.item) > 100
                    ? 100
                    : _getConfirmationProcent(props.item)
                  : _getConfirmationProcent(props.item).toFixed(1)
              }}%
            </td>
            <td style="color: #db9943">
              {{
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ] &&
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ].inProgressNumber
                  ? props.item[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        userData.school.currentYear
                    ].inProgressNumber
                  : 0
              }}
            </td>
            <td class="red--text">
              {{
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ] &&
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ].rejectedNumber
                  ? props.item[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        userData.school.currentYear
                    ].rejectedNumber
                  : 0
              }}
            </td>
            <td style="color: #800080">
              {{
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ] &&
                props.item[
                  "year" +
                    selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    userData.school.currentYear
                ].sentNumber
                  ? props.item[
                      "year" +
                        selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        userData.school.currentYear
                    ].sentNumber
                  : 0
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-progress-linear v-else indeterminate color="red"></v-progress-linear>
    </v-card>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  name: "smart-home",
  components: {},
  computed: {
    ...sync("*"),
  },
  data() {
    return {
      selectedHalfYear: null,
      renderComponent: true,
      teachers: null,
      headerNames: [
        {
          text: "No",
          align: "start",
          value: "index",
          width: "1%",
        },
        {
          text: "Багшийн нэр",
          value: "DISPLAY_NAME",
        },
        {
          text: "Xичээлийн тоо",
          value: "lessons.length",
        },
        {
          text: "Баталгаажсан Хувь",
          value: "confirmedPercent",
        },
        {
          text: "Хянагдаж байгаа",
          value: "inProgressNumberAll",
        },
        {
          text: "Цуцлагдсан",
          value: "rejectedNumberAll",
        },
        {
          text: "Бүртгэсэн",
          value: "sentNumberAll",
        },
      ],
      loading: false,
    };
  },
  created() {
    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);
    this.selectedHalfYear = {
      yearId: 2,
      name: this.preText + " xичээлийн жил, 1-р xагас жил",
    };
  },
  watch: {
    selectedHalfYear() {
      if (this.userData.school) this._setupp();
    },
  },
  methods: {
    print(teacher) {
      console.log(teacher);
    },
    _getConfirmationProcent(teacher) {
      var xxx = teacher[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ]
        ? teacher[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.school.currentYear
          ].approvedNumber
        : 0;
      var yyy = teacher[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ]
        ? teacher[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.school.currentYear
          ].totalLessonStudentsNumber
        : 0;
      if (xxx && yyy) return (xxx / yyy) * 100;
      return 0;
    },

    _setupp() {
      this.loading = true;
      this.userData.school.ref
        .collection("teachers")
        .where("role", "==", "teacher")
        .where("deleted", "==", false)
        .orderBy("FIRST_NAME", "asc")
        .onSnapshot((docs) => {
          this.teachers = [];
          docs.forEach(async (doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;
            if (this._getConfirmationProcent(teacher) > 100) {
              fb.db
                .collection("problematicProgramsUnelgeeAnalytics")
                .doc(String(teacher.id))
                .set({
                  teacherRef: teacher.ref,
                  createdAt: new Date(),
                })
                .then(() => {
                  console.log("problem logged");
                });
            }
            this.teachers.push(teacher);
          });
          this.loading = false;
        });
    },
    _print(item) {
      console.log(item.ref.path);
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
};
</script>
<style>
.teacherMonitoringTable td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 32px !important;
  padding: 0px 0px 0px 2px;
}

.teacherMonitoringTable th {
  border: 1px solid #dddddd;
  text-align: center;
  height: 32px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}
</style>
